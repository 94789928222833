<template>
  <el-main>
    <SmallCard :info="smallInfo" @clickFn="openGrantDialog"></SmallCard>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="卡密类型：">
        <el-select size="small" v-model="ruleForm.type" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option v-for="item in cardTypeList" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="21px">
        <el-button size="small" type="primary" @click="getTicketList()">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="ruleForm.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
      <el-table-column prop="type" label="卡密类型" width="100" align="center">
        <template v-slot="{ row }">
          <span>{{ getSource(row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invite_code" width="200" label="卡密" align="center"></el-table-column>
      <el-table-column label="卡密状态" width="100" align="center">
        <template v-slot="{ row }">{{ getStatusName(row) }}</template>
      </el-table-column>
      <el-table-column label="领取状态" width="100" align="center">
        <template v-slot="{ row }">{{ row.user_id ? '已领取' : '未领取' }}</template>
      </el-table-column>
      <el-table-column label="发放时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="过期时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" size="small" v-if="!row.deleted" @click="loseActivity(row)">失效</el-button>
          <el-button type="text" size="small" v-else>已失效</el-button>
          <!-- <el-button type="text" size="small">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="ruleForm.total" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 添加卡密弹窗 -->
    <el-dialog title="添加卡密" :visible.sync="showAddDialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="卡密类型：">
          <el-select style="width: 100%" v-model="bindingZujuID" placeholder="请选择" class="classify_select">
            <el-option style="width: 500px" v-for="item in cardTypeList" :key="item.type" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发放数量：">
          <el-input style="width: 220px" v-model="fafang_num" placeholder="请输入发放数量："></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureGrant">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '../../components/paging.vue';
import { getDateformat } from '../../util/getDate';
import SmallCard from '@/components/smallCardBox.vue';
export default {
  components: {
    Paging,
    SmallCard,
  },
  data() {
    return {
      getDateformat,
      smallInfo: {
        img: require('@/assets/smallCardImgs/3.png'),
        title: '卡密',
        desc: '--',
        btnTxt: '添加卡密',
      },
      ruleForm: {
        page: 1,
        rows: 10,
        type: 0,
        total: 0,
        list: [],
      },
      //   1—剧场积分套餐 2—分销商套餐 3—vip套餐
      cardTypeList: [
        { type: 1, name: '剧场积分套餐' },
        { type: 2, name: '分销商套餐' },
        { type: 3, name: 'vip套餐' },
      ],
      showAddDialog: false,
      bindingZujuID: null,
      fafang_num: '',
    };
  },
  created() {
    this.getTicketList();
  },
  methods: {
    loseActivity(data) {
      this.$confirm('此操作将失效该卡密, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.loseActivity, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('操作成功');
            this.getTicketList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    sureGrant() {
      if (!this.bindingZujuID) {
        this.$message.warning('请选择发放类型');
        return;
      }
      if (!this.fafang_num) {
        this.$message.warning('请填写发放数量');
        return;
      }
      let obj = {
        type: this.bindingZujuID,
        num: this.fafang_num,
      };
      this.$axios.post(this.$api.shortVideo.sendInviteCode, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('发放成功');
          this.getTicketList();
          this.showAddDialog = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    openGrantDialog() {
      this.bindingZujuID = '';
      this.fafang_num = '';
      this.showAddDialog = true;
    },
    getSource(data) {
      let type = this.cardTypeList.find(i => i.type === data.type) || { name: '--' };
      return type.name;
    },
    getStatusName(data) {
      let nowTime = new Date().getTime() / 1000;
      if (data.status == 0) return '已使用';
      if (nowTime >= data.end_time) return '已过期';
      if (data.status == 1) return '未使用';
      return '--';
    },
    clearSearch() {
      this.ruleForm = {
        page: 1,
        rows: 10,
        type: 0,
        total: 0,
        list: [],
      };
      this.getTicketList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.ruleForm.rows = val;
      } else {
        this.ruleForm.page = val;
      }
      this.getTicketList();
    },
    getTicketList() {
      let obj = {
        page: this.ruleForm.page,
        rows: this.ruleForm.rows,
      };
      if (this.ruleForm.source) obj.comefrom = this.ruleForm.source;
      this.$axios.post(this.$api.shortVideo.InviteCodelist, obj).then(res => {
        if (res.code == 0) {
          this.ruleForm.total = res.result.total_number;
          this.ruleForm.list = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
